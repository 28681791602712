<template>
    <div class="not-found">
        <div class="errorCode">
            {{ $t('errors.oups') }}
        </div>
        <div class="errorDescription">
            {{ errorDescription }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'Error',
    computed: {
        errorCode() {
            const code = parseInt(this.$route.params.code, 10)
            const handledCodes = [401, 404, 500]
            if (handledCodes.includes(code)) {
                return code
            } else {
                return 0
            }
        },
        errorDescription() {
            return this.$t('errors.' + this.errorCode)
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.not-found {
    width: 50%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 150px;
    text-align: center;
}

.errorCode {
    font-family: $font_avenir_heavy;
    font-size: $veryBig;
}

.errorDescription {
    font-family: $font_avenir_light;
    font-size: $veryBig;
}

</style>
